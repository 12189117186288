<template>
  <div class="w-full flex flex-col">
    <bread-crumbs class="flex justify-end text-xs" :items="routes" />
    <h3 class="text-start mt-4 text-xl">Usuarios</h3>
    <h4 class="text-start mt-1 mb-4 text-gray-500 text-lg">Crear Usuarios</h4>
    <section class="w-full my-4 bg-white p-2">
      <div class="flex w-full justify-between">
        <h5 class="text-start mt- mb-4 text-gray-500 text-base">Usuarios</h5>
        <div class="flex">
          <div class="p-2 bg-green-400 rounded self-center">
            <icon @click.native="handleSave" class="text-white" name="FlatCheckIcon" />
          </div>
          <div class="p-2 ml-2 bg-gray-200 rounded self-center">
            <icon @click.native="handleClearFields" class="text-white" name="XIcon" />
          </div>
        </div>
      </div>
      <div class="flex w-full grid grid-cols-2 gap-4">
        <input-field
          idInput="name"
          idFormGroup="name-group"
          type="text"
          label="Nombre"
          v-model="user.name"
        />
        <input-field
          idInput="lastName"
          idFormGroup="lastName-group"
          type="text"
          label="Apellido"
          v-model="user.lastName"
        />
        <input-field
          idInput="Correo Eléctronico"
          idFormGroup="email-group"
          type="text"
          label="Palabra Clave"
          v-model="user.email"
        />
        <select-input
          idInput="accountType"
          idFormGroup="accountType-group"
          label="Tipo de cuenta"
          v-model="user.accountType"
          :options="accountTypes"
        />
        <input-field
          idInput="descount"
          idFormGroup="descount-group"
          type="text"
          label="Descuento"
          v-model="user.name"
        />
      </div>
    </section>
  </div>
</template>
<script>
import BreadCrumbs from '@/components/BreadCrumbs.vue';
import InputField from '@/components/InputField.vue';
import SelectInput from '@/components/SelectInput.vue';
import Icon from '@/components/Icon.vue';

export default {
  name: 'CreateUser',
  components: {
    BreadCrumbs, InputField, SelectInput, Icon,
  },
  data() {
    return {
      routes: [],
      user: {
        name: '',
        lastName: '',
        email: '',
        accountType: null,
      },
      accountTypes: [
        { text: 'Seleccione una opcion', value: null },
        { text: 'Personal', value: 'personal' },
        { text: 'Empresarial', value: 'empresarial' },
        { text: 'Envios Familiares', value: 'envios' },
        { text: 'Sistema', value: 'sistema' },
      ],
    };
  },
  methods: {
    loadRoutesBreadCrumbs(router) {
      const {
        history: { _startLocation, current, router: { options: { routes } } },
      } = router;
      const startRoute = routes.find((route) => route.path === _startLocation);
      this.routes.push(startRoute);
      if (startRoute.name !== current.name) this.routes.push(current);
    },
    handleSave() {
      // Logic here
    },
    handleClearFields() {
      // logic here
    },
  },
  mounted() {
    this.loadRoutesBreadCrumbs(this.$router);
  },
};
</script>
